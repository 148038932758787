import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBsbAuzeEqvEmTnGBsLw3yKOlMcWrAoMQc",
    authDomain: "newsletter-medlooks.firebaseapp.com",
    projectId: "newsletter-medlooks",
    storageBucket: "newsletter-medlooks.appspot.com",
    messagingSenderId: "276096966822",
    appId: "1:276096966822:web:be6614e173a40f92b4d467"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
export default db;

