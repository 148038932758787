import React from 'react';

import FlipCountdown from '@rumess/react-flip-countdown';

import { FacebookFilled, TwitterOutlined, InstagramFilled, LinkedinFilled } from '@ant-design/icons';

import { useState } from 'react';

import db from './FireBase';
import firebase from 'firebase/compat/app';

function ComingSoon() {

const [message, setMessage] = useState ()    

const [input , setInput] = useState ('');
const inputHandler = (e) => {
    setInput(e.target.value);
};
const submitHandler = (e) => {
    e.preventDefault();
    if(input){
        //add to firebase
        db.collection('emails').add({
            email: input,
            time: firebase.firestore.FieldValue.serverTimestamp(),
        });
        setInput('');
        setMessage('Thank you for sign up!')
        setTimeout(() => {
            setMessage('');
        }, 3000)
    }
};

  return (
        <div className='flex'>
            <div className='w-2/4 left'>
                <div className='p-5 ml-32'>
                    <div className='brand mt-10'>
                        <a href='/' className='logo-home' title='Medlooks Home'>
                            <img src='https://res.cloudinary.com/proexaminesurgicals/image/upload/v1701330352/medlooks/medlooks_brand_logo.svg'
                            className='hidden-sm-down logo-big'
                            alt='Medlooks'
                            />
                        </a>
                    </div>
                    <div className='under-text mt-24 heading-text'>
                        <h1>Under</h1>
                        <h1>Construction</h1>
                        <div className='dec-text mt-4'>
                            <p>Our website is currently undergoing scheduled maintenance. We
                            should be back shortly. Thank you for your patience.</p>
                        </div>
                    </div>
                    <div className='newsletter mt-6'>
                            <div className='content mb-5'>
                                <form onSubmit={submitHandler} className='subscription'>
                                    <input type='email' onChange={inputHandler} value={input} className="add-email" placeholder='Enter your email'/>
                                    <button type='submit' className='submit-email'>
                                    Notify Me
                                    </button>
                                </form>
                            </div>
                        {message && <p className='alert'>{message}</p>}
                        <p className='dec-text2'>Sign up now to get early notification of our lauch date!</p>
                    </div>
                    <div className='get-touch'>
                        <div className='flex gap-4 items-baseline'>
                            <div><p className='font-semibold'>Stay in touch :</p></div>
                            <div className='social-link'>
                                <ul>
                                    <li><a href="https://facebook.com/medlooks"><FacebookFilled style={{ color: '#1877f2' }}/></a></li>
                                    <li><a href="https://twitter.com/medlooks"><TwitterOutlined style={{ color: '#1DA1F2' }}/></a></li>
                                    <li><a href="https://www.instagram.com/wearmedlooks/?hl=en"><InstagramFilled style={{ color: '#C13584' }} /></a></li>
                                    <li><a href="https://in.linkedin.com/company/medlooks"><LinkedinFilled style={{ color: '#0077b5' }} /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-2/4 right'>
                <div className='flex justify-center items-center h-full'>
                    <div>
                        <FlipCountdown
                        hideYear
                        hideMonth
                        dayTitle='Days'
                        hourTitle='Hours'
                        minuteTitle='Minutes'
                        secondTitle='Seconds'
                        endAt={'2024-1-17 01:26:58'}
                        endAtZero
                        theme='light'
                        onTimeUp={() => console.log("Time's up ⏳")}
                        />
                    </div>
                </div>
            </div>
        </div>
  )
}

export default ComingSoon